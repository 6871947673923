require("./index.scss");

import * as dompack from 'dompack';

import "@mod-system/js/wh/integration"; //we're a WebHare site
import "@mod-publisher/js/richcontent/all";

//At minimum, activates required CSS and JSON/RPC links
import * as forms from '@mod-publisher/js/forms';

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__uploadfield", node => new UploadField(node));

//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node));

forms.setup({ validate: true });

//require('./index.scss');
//alert("hello2");
function cleanupEmptyParagraphs(pnode)
{//cleanup empty paragraphs between fullwidth blocks
  for(let node of pnode.querySelectorAll(".contents--fullwidth"))
  {
    let prevnode = node.previousElementSibling;
    if(prevnode && prevnode.nodeName == "P" && (prevnode.innerHTML == "" || prevnode.innerHTML == "<br>") )
      node.parentNode.removeChild(prevnode);
  }
}

dompack.register("article", node => cleanupEmptyParagraphs(node) );

